import { useState, useEffect } from 'react';
import useApiCaller from '../hooks/use-api-caller';
import useCurrentPage from '../hooks/use-set-current-page';
import { IContactDefaults, IContactRequest } from '../types/contact';
import Button from './Button';
import { userInfoAtom } from '../store/user-info';
import { useRecoilState } from 'recoil';
import { datadogLogs } from '@datadog/browser-logs';

export default function ContactUsForm() {
  const currentPage = useCurrentPage();
  const apiCaller = useApiCaller();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [topic_type, setTopic_type] = useState('');
  const [referringPage, setReferringPage] = useState('');

  const [body, setBody] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [userInfo] = useRecoilState(userInfoAtom);
  const BODY_LIMIT_LENGTH = 5000;

  useEffect(() => {
    apiCaller
      .getContactUsDefaults()
      .then((contactUsDefaults: IContactDefaults) => {
        setReferringPage(currentPage.get());
        if (contactUsDefaults) {
          setFirstName(contactUsDefaults.first_name ?? '');
          setLastName(contactUsDefaults.last_name ?? '');
          setEmail(contactUsDefaults.email ?? '');
        }
      })
      .catch((err) => {
        console.log('CONTACT_US_DEFAULTS ERROR', err);
      });
  }, []);

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    // This is a hack to trigger form validation without proper form submission
    // Since the ContactUs is being submitted via a remote API request, client
    // side form validation is not being run. By checking the validity before
    // submission errors can be detected.
    let form = document.getElementById('feedback-form') as HTMLFormElement;
    if (!form.checkValidity()) {
      // In case of an error we will simulate a form submission to trigger
      // browser popups explaining the error
      let tmpSubmit = document.createElement('button');
      form.appendChild(tmpSubmit);
      tmpSubmit.click();
      form.removeChild(tmpSubmit);
    } else {
      setSubmitting(true);
      setError(false);

      const contactUsResponse: IContactRequest = {
        topic_type: topic_type,
        email: email,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        page: referringPage,
        user_agent: navigator.userAgent,
        body: body,
        license_entitlement: userInfo.license_entitlement ?? '',
        client_company: userInfo.client_company ?? '',
        client_company_account: userInfo.client_company_account ?? '',
      };

      const msg = `CONTACT_US:${JSON.stringify(contactUsResponse)}`;
      datadogLogs.logger.info(msg, contactUsResponse);

      apiCaller
        .postContactUs(contactUsResponse)
        .then(() => {
          setSubmitted(true);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const getRemaining = () => {
    return BODY_LIMIT_LENGTH - body.length;
  };

  const onBodyChange = (body: string) => {
    setBody(body.slice(0, BODY_LIMIT_LENGTH));
  };

  return (
    <div>
      {!submitted && (
        <div>
          <p className="lg:text-black text-xs leading-5 mb-6">
            We are very interested in your feedback. Please tell us about anything you particularly liked or disliked, anything you feel
            should be improved or added.
          </p>

          <form id="feedback-form">
            <div className="ml-2 mr-2">
              <div className="flex">
                <div className="w-1/2 pr-2">
                  <div className="flex flex-col">
                    <label className="lg:text-black text-xs mb-0  pl-2" htmlFor="firstName">
                      First Name*
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="mb-4 p-2 rounded border border-ow-grey"
                      value={firstName}
                      required={true}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </div>
                </div>
                <div className="w-1/2 pl-2">
                  <div className="flex flex-col">
                    <label className="lg:text-black text-xs mb-0 pl-2" htmlFor="lastName">
                      Last Name*
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="mb-4 p-2 rounded border border-ow-grey"
                      value={lastName}
                      required={true}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </div>{' '}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2 pr-2">
                  <div className="flex flex-col">
                    <label className="lg:text-black text-xs mb-0 pl-2" htmlFor="email">
                      Email*
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="w-full mb-4 p-2 rounded border border-ow-grey"
                      value={email}
                      required={true}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </div>
                <div className="w-1/2 pl-2">
                  <div className="flex flex-col">
                    <label className="lg:text-black text-xs mb-0 pl-2" htmlFor="phone">
                      Phone*
                    </label>
                    <input
                      type="text"
                      id="phone"
                      className="w-full mb-4 p-2 rounded border border-ow-grey"
                      value={phone}
                      required={true}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/2">
                <div className="flex flex-col pr-2">
                  <label className="lg:text-black text-xs mb-0  pl-2" htmlFor="topicType">
                    Topic*
                  </label>
                  <select
                    id="topicType"
                    className="mb-4 p-2 rounded border border-ow-grey"
                    value={topic_type}
                    required={true}
                    onChange={(event) => setTopic_type(event.target.value)}
                  >
                    <option value="">Select...</option>
                    <option value="FEEDBACK">Feedback</option>
                    <option value="HELP">Help</option>
                  </select>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex flex-col pr-2">
                  <label className="lg:text-black text-xs mb-0  pl-2" htmlFor="referringPage">
                    Please indicate the page you are referring to
                  </label>
                  <input
                    type="text"
                    id="referringPage"
                    className="w-full mb-4 p-2 rounded border border-ow-grey"
                    value={referringPage}
                    onChange={(event) => setReferringPage(event.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <label className="lg:text-black text-right text-xs mb-0  pl-2" htmlFor="initialMessage">
                  Initial Message*
                </label>
                <label className="lg:text-black text-right text-xs mb-0 pr-2">{getRemaining()} characters remaining</label>
              </div>
              <div>
                <textarea
                  id="initialMessage"
                  className="w-full mb-4 p-2 rounded border border-ow-grey"
                  rows={10}
                  minLength={10}
                  maxLength={5000}
                  onChange={(event) => onBodyChange(event.target.value)}
                  placeholder="Type your feedback here (10 characters or more)"
                  required={true}
                ></textarea>
              </div>
              <Button text="Submit" type="button" disabled={submitting} onClick={handleSubmit} />
              {error && <p className="text-ow-red font-bold mt-2 text-center">An error occurred, please try again.</p>}
            </div>
          </form>
        </div>
      )}

      {submitted && (
        <div>
          <h3 className="font-mmc text-xl lg:text-ow-primary">Thanks, your response has been received.</h3>
        </div>
      )}
    </div>
  );
}
