import Highcharts, { ChartPositionObject, Chart as HighchartsChart, Options, Point } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exportingModule from 'highcharts/modules/exporting';
import offlineExportingModule from 'highcharts/modules/offline-exporting';
import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { useRecoilState } from 'recoil';
import {
  articleOverrideDateRangeAtom,
  closePopupAtom,
  customID,
  customRange,
  fixedModeAtom,
  pageDateRangeAtom,
  pageDateRangeOptions,
} from '../store/timerange';
import { ITimeseries } from '../types/company';
import { formatDateString } from '../utils/dates';
import { CompanyPopup } from './CompanyPopup';
import { Tooltip } from './Tooltip';
import { ExtendedPoint, TooltipAnchorPoint } from '../extensions/point';
import { TooltipData, TooltipOptionsExtended } from '../types/tooltip-data';
import { IDataFromTimerange, SeriesContentType } from '../types/company-chart';
import '../styles/CompanyGraphs.scss';
import { ExtendedAxis } from '../extensions/axis';
import {
  black,
  grey,
  blue,
  green,
  red,
  negativeArticlesSeriesName,
  negativeArticlesId,
  downgradeScoreId,
  GRAPH_FONT_SIZE,
} from '../constants/company-chart';
import { readjustLabels, createArticleOverrideDateRange, repositionTicks, getSeriesValues } from '../utils/company-chart-functions';
import { IntervalLengthConstants } from '../constants/interval-length-constants';
import '../extensions/highcharts-plugins';
import { SeriesData } from '../utils/series-data';
import { SizeChangeDetector } from '../utils/size-change-detector';
import { IWidthLevel } from '../constants/width-levels';
import useChartTitle from '../hooks/use-chart-title';
import { breakPoints, scoreBucketName } from '../constants/downgrade-probability';

exportingModule(Highcharts);
offlineExportingModule(Highcharts);

const unselectedOpacity = 0.5;

type CompanyGraphsProps = {
  timeseriesData: ITimeseries[];
  companyName: string;
};

export type CompanyGraphsRef = {
  exportChartAsPNG: () => void;
  resetZoom: () => void;
};

const CompanyGraphs = forwardRef<CompanyGraphsRef, CompanyGraphsProps>(function CompanyGraphs({ timeseriesData, companyName }, ref) {
  const [pageDateRange, setPageDateRange] = useRecoilState(pageDateRangeAtom);
  const chartRef = useRef<any>(null);
  const shouldRedrawRef = useRef(true);
  const [fixedMode, setFixedMode] = useRecoilState(fixedModeAtom);
  const [closePopup, setClosePopup] = useRecoilState(closePopupAtom);
  const [chartKey, setChartKey] = useState(0);
  const [yMinMax, setYMinMax] = useState({ min: 0, max: 1 });

  useEffect(() => {
    if (closePopup) {
      hidePopup();
      setClosePopup(false);
    }
  }, [closePopup]);

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const callback = useCallback((chart: HighchartsChart) => {
    setChart(chart);
  }, []);

  let tooltipData: TooltipData | null = null;
  let [fixedTooltipPoint, setFixedTooltipPoint] = useState<TooltipAnchorPoint>();
  let [fixedTooltipPosition, setFixedTooltipPosition] = useState<ExtendedPoint[]>();

  const [articleOverrideDateRange, setArticleOverrideDateRange] = useRecoilState(articleOverrideDateRangeAtom);

  const hidePopup = () => {
    setFixedMode(false);
    setTimeout(() => {
      // This hack is needed for hiding tooltip after fixed popup is closed
      chart?.tooltip?.hide();
      chartRef?.current?.chart?.tooltip.hide();
    });
  };

  const seriesData = new SeriesData(timeseriesData);
  const sizeChangeDetector = new SizeChangeDetector();

  const calculateDataForChart = (_level?: IWidthLevel) => {
    const daysInOneBar = sizeChangeDetector.getLevelDaysByRange(pageDateRange);

    // A small screen resoltion for mobile devices should show a one month per column representation,
    // this feature needs to be implemented, still.
    return seriesData.calculateDataForTimerange(pageDateRange, daysInOneBar);
  };

  let [dataFromTimerange, setDataFromTimerange] = useState<IDataFromTimerange>(calculateDataForChart);
  let [screenWidthLevel, setScreenWidthLevel] = useState<IWidthLevel>();

  sizeChangeDetector.attachLevelChangedCallback((level?: IWidthLevel) => {
    setScreenWidthLevel(level);
  });

  const redrawChart = () => {
    shouldRedrawRef.current = true;
    (chartRef.current as any)?.chart.redraw();
    readjustLabels((chartRef.current as any)?.chart || chart);
  };

  const handleResize = () => {
    hidePopup();
    redrawChart();
    sizeChangeDetector.handleResize();
  };

  const filterDataByDate = async (minDate: string, maxDate: string) => {
    setPageDateRange({ value: customID, label: customRange, earliestDate: minDate, latestDate: maxDate });
    setChartKey((prevKey) => prevKey + 1);
  };

  const resetDateRange = () => {
    setPageDateRange(pageDateRangeOptions[0]);
  };

  const resetZoom = () => {
    resetDateRange();
    setYMinMax({ min: 0, max: 1 });
  };

  useEffect(() => {
    // a stateful screenWidthLevel handling is needed for getting a
    // recalculation with correct pageDateRange values done and
    // rerender the graph correctly
    setDataFromTimerange(calculateDataForChart());

    // the timeout is necessary for re-adjusting the labels correctly
    setTimeout(() => redrawChart());
  }, [screenWidthLevel]);

  useEffect(() => {
    hidePopup();
    scrollToTop();
    setDataFromTimerange(calculateDataForChart());

    // the timeout is necessary for re-adjusting the labels correctly
    setTimeout(() => redrawChart());
  }, [pageDateRange]);

  useEffect(() => {
    if (!fixedMode) {
      setArticleOverrideDateRange('');
    }
  }, [fixedMode]);

  useEffect(() => {
    if (fixedMode && fixedTooltipPosition && fixedTooltipPosition.length > 0) {
      chart?.tooltip.refresh(fixedTooltipPosition);
    }
  }, [fixedTooltipPosition]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (articleOverrideDateRange === null) {
      hidePopup();
      scrollToTop();
    }
  }, [articleOverrideDateRange]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getTooltipPosition = (
    chartPosition: ChartPositionObject,
    hoverPoints: ExtendedPoint[],
    labelWidth: number,
    labelHeight: number
  ): TooltipAnchorPoint => {
    let tooltipX: number, tooltipY: number;

    if (!hoverPoints || hoverPoints.length === 0) {
      return { x: 0, y: 0 };
    }

    tooltipX = hoverPoints[0].plotX! - labelWidth / 6 + 3;

    const highestPoint = [...hoverPoints].sort((a, b) => a.plotY! - b.plotY!)[0];
    tooltipY = chartPosition.top - 30 + highestPoint.plotY! - labelHeight * 2.75;

    return {
      x: tooltipX,
      y: tooltipY,
      category: hoverPoints[0].category.toString(),
    };
  };

  const exportChartAsPNG = () => {
    const chart = (chartRef.current as any)?.chart;
    if (!chart) {
      return;
    }

    // Note: highcharts export feature creates a second chart with different structure. Unfortunately,
    //       the export-chart instance is set to the tooltip component. That's why the following lines
    //       preserve and reset the original chart object before and after exporting.
    const c = chart;
    chart.exportChartLocal();
    setChart(c);
  };

  const chartTitle = useChartTitle();

  const getColumnHoverPoints = (hoverPoints: ExtendedPoint[], allSeries: any): ExtendedPoint[] => {
    if (!hoverPoints || hoverPoints.length === 0) {
      return hoverPoints;
    }

    const x = hoverPoints[0].x;
    const points: Point[] = [];
    allSeries.forEach((series: { index: SeriesContentType; points: any[] }) => {
      if (series?.index === SeriesContentType.negativeArticles || series?.index === SeriesContentType.positiveArticles) {
        points.push(...series?.points.filter((point) => point.x === x));
      }
    });
    return points as ExtendedPoint[];
  };

  const defaultPlotLineLabelOptions = {
    y: 20,
    style: { color: '#949494' }, // ow-grey
  };

  const tooltipOptions: TooltipOptionsExtended = {
    useHTML: true,
    outside: true,
    hideDelay: 0,
    borderWidth: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    shadow: false,
    shared: true,
    stickOnContact: true,
    remainOnMouseout: function () {
      return fixedMode;
    },
    positioner: function (labelWidth, labelHeight) {
      if (!fixedMode) {
        const chart = this.chart;
        let chartPosition = chart.pointer.getChartPosition();
        let hoverPoints = chart.hoverPoints as ExtendedPoint[];
        const position = getTooltipPosition(chartPosition, hoverPoints, labelWidth, labelHeight);
        return position;
      } else {
        return {
          x: fixedTooltipPoint!.x,
          y: fixedTooltipPoint!.y,
        };
      }
    },
  };

  const options: Options = {
    // We should use the accessibility package and make this accessible
    // but we haven't so turn this off so we don't get annoying warnings
    accessibility: {
      enabled: false,
    },
    chart: {
      backgroundColor: 'transparent',
      height: 575,
      spacing: [10, 0, 0, 0],
      animation: true,
      zooming: {
        type: 'xy',
      },
      events: {
        render: function () {
          const chart = (chartRef.current as any)?.chart;
          if (!chart) {
            return;
          }
          chart.redLine?.destroy();
          chart.greenLine?.destroy();
          const yAxis = (chart?.get(negativeArticlesId) as Highcharts.Series)?.yAxis as ExtendedAxis;
          const negativeAxisLength = yAxis.max ?? 0;
          const positiveAxisLength = Math.abs(yAxis.min ?? 0);
          const tickLength = negativeAxisLength + positiveAxisLength;
          const tickToHeightFactor = yAxis.height / tickLength;
          const negativeLineLength = tickToHeightFactor * negativeAxisLength;
          const xAxis = chart.xAxis[0];
          const x = xAxis.width + xAxis.left;
          const width = 2;
          chart.redLine = this.renderer
            .rect(x, yAxis.top, width, negativeLineLength)
            .attr({
              'stroke-width': width,
              stroke: red,
              zIndex: 3,
            })
            .add();
          const positiveLineLength = tickToHeightFactor * positiveAxisLength;
          chart.greenLine = this.renderer
            .rect(x, yAxis.top + negativeLineLength, width, positiveLineLength)
            .attr({
              'stroke-width': width,
              stroke: green,
              zIndex: 3,
            })
            .add();
        },
        click: function (event) {
          hidePopup();
          event.stopPropagation();
        },
        selection: function (e) {
          const yMin = e.yAxis[0].min;
          const yMax = e.yAxis[0].max;
          setYMinMax({ min: yMin, max: yMax });

          const xMin = e.xAxis[0].min;
          const xMax = e.xAxis[0].max;
          const xAxis = this.xAxis[0];

          const categories = xAxis.categories;
          if (categories) {
            const minCategoryIndex = Math.floor(xMin);
            const maxCategoryIndex = Math.ceil(xMax);

            const minCategory = categories[minCategoryIndex];
            const maxCategory = categories[maxCategoryIndex];

            if (minCategory && maxCategory) {
              filterDataByDate(minCategory, maxCategory);
            }
          }

          return true;
        },
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: dataFromTimerange.xAxisCategories,
      title: { text: '' },
      lineColor: grey,
      lineWidth: 1,
      tickPositioner: function () {
        return repositionTicks(this, dataFromTimerange);
      },
      tickmarkPlacement: 'on',
      tickWidth: 1,
      tickColor: grey,
      labels: {
        style: {
          color: black,
          fontSize: GRAPH_FONT_SIZE,
        },
        formatter: function () {
          const label = this.axis.defaultLabelFormatter.call(this);
          const positions = this.axis.tickPositions || [];

          if (
            this.isLast &&
            dataFromTimerange.daysInOneTick > IntervalLengthConstants.Month &&
            positions.length > 2 &&
            Math.abs(this.axis.toPixels(this.axis.getExtremes().dataMax, false) - this.axis.toPixels(positions.at(-1)!, false)) <
              Math.abs(this.axis.toPixels(positions.at(-1)!, false) - this.axis.toPixels(positions.at(-2)!, false)) / 2
          ) {
            return '';
          }
          return formatDateString(label, dataFromTimerange.daysInOneTick);
        },
      },
    },
    yAxis: [
      // Axis for the line graph showing the FSS score
      {
        title: {
          text: 'FSS Score',
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        labels: {
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
          formatter: function () {
            const label = this.axis.defaultLabelFormatter.call(this);
            const value = parseFloat(label);
            return `${value * 100}`;
          },
        },
        min: yMinMax.min,
        max: yMinMax.max,
        tickPositions: [0.0, 0.2, 0.4, 0.6, 0.8, 1.0],
        lineColor: blue,
        lineWidth: 4,
        gridLineWidth: 0,
        plotLines: [
          { color: '#DADADA', value: breakPoints[1] / 100, label: { text: scoreBucketName[1], ...defaultPlotLineLabelOptions } },
          { color: '#DADADA', value: breakPoints[2] / 100, label: { text: scoreBucketName[2], ...defaultPlotLineLabelOptions } },
          { color: '#DADADA', value: breakPoints[3] / 100, label: { text: scoreBucketName[3], ...defaultPlotLineLabelOptions } },
          { color: '#DADADA', value: breakPoints[4] / 100, label: { text: scoreBucketName[4], ...defaultPlotLineLabelOptions } },
          { color: 'transparent', value: 1, label: { text: scoreBucketName[5], ...defaultPlotLineLabelOptions } },
        ], // 'ow-light-grey': ,
      },
      // Axis for the bar chart showing the number of articles
      {
        allowDecimals: false,
        title: {
          text: 'News articles',
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        tickWidth: 0,
        lineWidth: 0,
        labels: {
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
          formatter: function () {
            const value = Math.abs(parseFloat(this.value as string));
            return value >= 1000 ? value / 1000 + 'K' : value + '';
          },
        },
        gridLineWidth: 0,
        // Show this axis label on the RHS
        opposite: true,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 576,
          },
          chartOptions: {
            yAxis: [
              {
                title: {
                  text: '',
                },
              },
              {
                title: {
                  text: '',
                },
              },
            ],
          },
        },
      ],
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        pointPadding: -0.32,
        opacity: unselectedOpacity,
        states: {
          hover: {
            enabled: true,
            opacity: 1,
          },
        },
      },
      series: {
        stacking: 'normal',
        findNearestPointBy: 'xy',
        zIndex: 10,
        cursor: 'pointer',
        stickyTracking: true,
        point: {
          events: {
            click: function (event) {
              setFixedMode(true);

              const chartPosition = this.series?.chart.pointer.getChartPosition();
              let hoverPoints = this.series?.chart.hoverPoints as ExtendedPoint[];

              const labelWidth = 216,
                labelHeight = 154;
              const position = getTooltipPosition(chartPosition, hoverPoints, labelWidth, labelHeight);
              setFixedTooltipPoint(position);

              setArticleOverrideDateRange(createArticleOverrideDateRange(position.category!));

              const series = this.series?.chart.series;
              const { downgradeValue, isEstimated, positiveArticlesValue, negativeArticlesValue } = getSeriesValues(chart, series, this.x);

              tooltipData = {
                x: null,
                y: null,
                category: this.category as string,
                positiveArticles: positiveArticlesValue,
                negativeArticles: negativeArticlesValue,
                downgradeScore: downgradeValue,
                isEstimated: isEstimated,
                daysInOneBar: dataFromTimerange.daysInOneBar,
              };

              const currentHoverPoints = this.series?.chart.hoverPoints as ExtendedPoint[];
              if (currentHoverPoints) {
                setFixedTooltipPosition(this.series?.chart.hoverPoints as ExtendedPoint[]);
              }

              event.stopPropagation();
            },
          },
        },
        events: {
          legendItemClick: function (e) {
            const series = chart?.get(negativeArticlesId) as Highcharts.Series;

            if (e.target.getName() === negativeArticlesSeriesName) {
              series?.setData(series?.data.map((pItem) => -(pItem.y ?? 0)));
            }
          },
        },
      },
    },
    // using an own type for tooltip options
    tooltip: tooltipOptions,
    legend: {
      enabled: true,
      itemStyle: { color: black, fontSize: GRAPH_FONT_SIZE },
      symbolRadius: 0,
    },
    series: dataFromTimerange.series,
    exporting: {
      fallbackToExportServer: false,
      allowHTML: true,
      enabled: true,
      chartOptions: {
        // specific options for the exported image
        chart: {
          height: 575,
          backgroundColor: 'white',
          events: {
            render: function () {
              const chart = (chartRef.current as any)?.chart;
              if (!chart) {
                return;
              }
              chart.redLine?.destroy();
              chart.greenLine?.destroy();
              const yAxis = (chart?.get(negativeArticlesId) as Highcharts.Series)?.yAxis as ExtendedAxis;
              const negativeAxisLength = yAxis.max ?? 0;
              const positiveAxisLength = Math.abs(yAxis.min ?? 0);
              const tickLength = negativeAxisLength + positiveAxisLength;
              const tickToHeightFactor = yAxis.height / tickLength;
              const negativeLineLength = tickToHeightFactor * negativeAxisLength;
              const xAxis = chart.xAxis[0];
              const x = this.chartWidth - xAxis.right;
              const width = 2;
              chart.redLine = this.renderer
                .rect(x, yAxis.top, width, negativeLineLength)
                .attr({
                  'stroke-width': width,
                  stroke: red,
                  zIndex: 3,
                })
                .add();
              const positiveLineLength = tickToHeightFactor * positiveAxisLength;
              chart.greenLine = this.renderer
                .rect(x, yAxis.top + negativeLineLength, width, positiveLineLength)
                .attr({
                  'stroke-width': width,
                  stroke: green,
                  zIndex: 3,
                })
                .add();
            },
          },
        },
        title: {
          useHTML: true,
          text: chartTitle.formatCompanyChartTitle(),
          align: 'center',
        },
        tooltip: { enabled: false },
      },
      scale: 2,
      sourceWidth: 1360,
      sourceHeight: 575,
      filename: `chart-${companyName}`,
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  useImperativeHandle(ref, () => ({
    exportChartAsPNG,
    resetZoom,
  }));

  return (
    <div>
      <div className="sm:hidden flex justify-between">
        <span className="text-xs text-black">FSS Score</span>
        <span className="text-xs text-black">News articles</span>
      </div>
      <HighchartsReact key={chartKey} ref={chartRef} highcharts={Highcharts} options={options} callback={callback} />

      <Tooltip chart={chart}>
        {(formatterContext) => {
          let { x } = formatterContext;

          if (fixedMode) {
            x = tooltipData?.category ?? x;
          }

          const series = chart?.series;
          // find index from first array using category (x-axis value)
          const index = (chart?.get(downgradeScoreId) as Highcharts.Series).data.find((i) => i.category === x)?.index!;
          const { downgradeValue, isEstimated, positiveArticlesValue, negativeArticlesValue } = getSeriesValues(chart, series, index);

          tooltipData = {
            x: null,
            y: null,
            category: x as string,
            positiveArticles: positiveArticlesValue,
            negativeArticles: negativeArticlesValue,
            downgradeScore: downgradeValue,
            isEstimated: isEstimated,
            daysInOneBar: dataFromTimerange.daysInOneBar,
          };

          return <CompanyPopup {...tooltipData} />;
        }}
      </Tooltip>
    </div>
  );
});

export default CompanyGraphs;
