import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useApiCaller from '../hooks/use-api-caller';
import { isOktaEnabled } from '../utils/config';
import { useOktaAuth } from '@okta/okta-react';
import OktaAuth from '@okta/okta-auth-js';

export default function IdleWatcher() {
  const TIMEOUT_IN_MINUTES = 120;

  const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

  let timeoutId: string | number | NodeJS.Timeout | undefined;

  const apiCaller = useApiCaller();
  const oktaEnabled = isOktaEnabled();
  /* eslint-disable react-hooks/rules-of-hooks */
  const oktaAuth: OktaAuth | null = oktaEnabled ? useOktaAuth().oktaAuth : null;

  const handleLogout = oktaEnabled
    ? () => {
        oktaAuth?.signOut();
        apiCaller.logout();
      }
    : () => {};

  const setInactive = () => {
    toast.info(`Logging out due to inactivity of ${TIMEOUT_IN_MINUTES} minutes!`);
    setTimeout(() => handleLogout(), 5000);
  };

  const resetTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      // Perform logout or any other action here
      setInactive();
    }, TIMEOUT_IN_MINUTES * 60000);
  };

  const onEvent = () => {
    resetTimeout();
  };

  useEffect(() => {
    events.forEach((event) => {
      document.addEventListener(event, onEvent);
    });

    resetTimeout();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      events.forEach((event) => {
        document.removeEventListener(event, onEvent);
      });
    };
  }, []);

  return <div></div>;
}
