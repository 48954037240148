import MultiSelectDropdown from '../components/MultiSelectDropdown';
import { useRecoilState } from 'recoil';
import { filterCountriesAtom, filterIndustriesAtom, filterListsAtom } from '../store/filters';
import { convertToValueLabel } from '../utils/compare-functions';

export default function CompareFilters({
  lists,
  industries,
  countries,
  hideList,
}: Readonly<{
  lists: string[];
  industries: string[];
  countries: string[];
  hideList: boolean;
}>) {
  const [filterCountries, setFilterCountries] = useRecoilState(filterCountriesAtom);
  const [filterIndustries, setFilterIndustries] = useRecoilState(filterIndustriesAtom);
  const [filterLists, setFilterLists] = useRecoilState(filterListsAtom);

  const resetFilters = () => {
    setFilterCountries([]);
    setFilterIndustries([]);
    if (!hideList) {
      setFilterLists([]);
    }
  };

  return (
    <div className="flex items-start">
      <div className="mr-2 font-bold my-[9px]">Filter by:</div>
      <div>
        <MultiSelectDropdown
          width="auto"
          options={countries}
          selectedOptions={convertToValueLabel(filterCountries)}
          onSelectionChange={setFilterCountries}
          placeHolder="Regions"
        />
      </div>
      <div className="fss-button-spacer"></div>
      {!hideList && (
        <div className="flex items-center">
          <div className="hidden xl:inline">
            <MultiSelectDropdown
              width="auto"
              options={lists}
              selectedOptions={convertToValueLabel(filterLists)}
              onSelectionChange={setFilterLists}
              placeHolder="Lists"
            />
          </div>
        </div>
      )}
      {!hideList && <div className="fss-button-spacer hidden xl:inline"></div>}
      <div className="hidden lg:inline">
        <MultiSelectDropdown
          width="auto"
          options={industries}
          selectedOptions={convertToValueLabel(filterIndustries)}
          onSelectionChange={setFilterIndustries}
          placeHolder="Industries"
        />
      </div>
      <div className="fss-button-spacer"></div>
      <div className="flex items-center">
        <button onClick={resetFilters} className="fss-chart-button" title="Reset filters">
          <img src="/icons/reset.svg" alt="Reset filters" />
          <div>Reset filters</div>
        </button>
      </div>
    </div>
  );
}
