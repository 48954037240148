import { useEffect, useState } from 'react';
import { ISearchedCompany } from '../types/company';
import useApiCaller from '../hooks/use-api-caller';
import Loading from '../components/Loading';
import Button from '../components/Button';
import SearchList from '../components/SearchList';
import FSSError from '../components/FSSError';
import { addSortFieldToCompanies } from '../utils/add-sort-field-to-companies';
import { triggerSearchAtom } from '../store/search-term';
import { useRecoilState } from 'recoil';
import { dataDogAddAction } from '../utils/data-dog-logging';
import { NavLink } from 'react-router-dom';

export default function SearchComponent({
  searchTerm,
  bulkMode,
}: Readonly<{
  searchTerm: string;
  bulkMode: boolean;
}>) {
  const apiCaller = useApiCaller();
  const [triggerSearch] = useRecoilState(triggerSearchAtom);

  const [searching, setSearching] = useState(false);
  const [searchedCompanies, setSearchedCompanies] = useState<ISearchedCompany[]>([]);
  const [searchError, setSearchError] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (searchTerm) {
      search(searchTerm);
    }
  }, [searchTerm, triggerSearch]);

  const search = (searchTerm: string) => {
    if (!searchTerm) {
      return;
    }
    setSearching(true);

    apiCaller
      .searchIncludingPortfolio(searchTerm, bulkMode, 0)
      .then((searchResponse) => {
        const newCompanies = searchResponse.items;
        const sortableCompanies = addSortFieldToCompanies(newCompanies);
        setSearchedCompanies(sortableCompanies);
        setSearching(false);
        setPage(0);

        const item_count = searchResponse.items.length;
        dataDogAddAction('search', {
          searchTerm: searchTerm,
          itemCount: item_count,
          page: page,
        });
      })
      .catch((err) => {
        console.error(err);
        setSearchError(true);
        setSearching(false);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const extendSearch = (searchTerm: string) => {
    if (!searchTerm) {
      return;
    }
    setSearching(true);

    apiCaller
      .searchIncludingPortfolio(searchTerm, bulkMode, (page + 1) * 100)
      .then((searchResponse) => {
        const newCompanies = [...searchedCompanies, ...searchResponse.items];
        const sortableCompanies = addSortFieldToCompanies(newCompanies);
        setSearchedCompanies(sortableCompanies);
        setSearching(false);
        setPage(page + 1);

        const item_count = searchResponse.items.length;
        dataDogAddAction('search', {
          searchTerm: searchTerm,
          itemCount: item_count,
          page: page,
        });
      })
      .catch((err) => {
        console.error(err);
        setSearchError(true);
        setSearching(false);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  return (
    <div>
      {searching && <Loading />}
      {searchError && <FSSError />}
      {!searching && !searchError && (
        <div>
          <SearchList searchedCompanies={searchedCompanies} searchTerm={searchTerm ?? ''} bulkMode={bulkMode} />
          {searchedCompanies.length > 0 && !searchError && !searching && !(searchedCompanies.length % 100) && (
            <div className="flex justify-center -mb-4">
              <div className="w-full lg:w-[150px]">
                <br />
                <Button
                  text="Load more"
                  onClick={() => {
                    extendSearch(searchTerm);
                  }}
                />
              </div>
            </div>
          )}
          {searching && <Loading />}
          <div className="text-xs text-center mt-8">
            <NavLink to="/contact">If you think there’s a company missing that we should be tracking, contact us.</NavLink>{' '}
          </div>
        </div>
      )}
    </div>
  );
}
