import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi } from 'ag-grid-community';
import { bulkSelectedCompanyAtom } from '../store/bulk-selected';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import Button from '../components/Button';
import useApiCaller from '../hooks/use-api-caller';
import { useRecoilState } from 'recoil';
import {
  IAlternativeCompany,
  IToScoreCompany,
  IScoredCompany,
  IScoringGridCompany,
  IScoredUpload,
  IScoredUploadResponse,
} from '../types/scoring-company';
import { createSQLToAdd } from '../utils/bulk-loader-sql';
import { IScoredCompaniesResponse } from '../types/api-caller';
import { getFeatureFlags } from '../utils/config';
import { createTemplate, downloadFile, exportCSV, parseImportFields } from '../utils/bulk-loader-import-export';
import { displayCompany, DELETE_ME, parseCompanyCode } from '../utils/bulk-loader-functions';

const isBulkLoaderEnabled = getFeatureFlags().has('bulk_loader');

const BulkLoaderGrid: React.FC = () => {
  const apiCaller = useApiCaller();
  const [bulkSelectCompany, setBulkSelectCompany] = useRecoilState(bulkSelectedCompanyAtom);

  const [searching, setSearching] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);

  const [scoringGrid, setScoringGrid] = useState<any[]>([]);
  const [gridApiRef, setGridApiRef] = useState<GridApi>();

  useEffect(() => {
    if (bulkSelectCompany !== '') {
      setCurrentCell(bulkSelectCompany);
      setBulkSelectCompany('');
    }
  }, [bulkSelectCompany]);

  const columnDefs = [
    { width: 70, headerName: 'ID', field: 'id' },
    { headerName: 'Email', field: 'email' },
    { width: 100, headerName: 'FCode', field: 'fcode' },
    { width: 100, headerName: 'Ticker symbol', field: 'ticker_symbol' },
    { width: 300, headerName: 'User company', field: 'user_company' },
    { width: 200, headerName: 'Country', field: 'country' },
    {
      width: 400,
      headerName: 'Found company',
      field: 'selected_company',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params: any) => {
        return {
          values: params.data.alternative_companies?.map((company: IAlternativeCompany) => {
            return displayCompany(company);
          }),
        };
      },
    },
    { width: 200, headerName: 'Upload', field: 'upload_status' },
  ];

  const setCurrentCell = (localSelectCompany: string) => {
    if (!gridApiRef) return;

    // if currently in drop down mode, get out of that mode
    if (gridApiRef.getEditingCells().length > 0) {
      gridApiRef.stopEditing(false);
    }

    const selectedNodes = gridApiRef.getSelectedNodes();
    if (selectedNodes && selectedNodes.length === 0) {
      toast.error('Please select a row to update');
      return;
    }

    selectedNodes.forEach((selectedNode) => {
      selectedNode.setDataValue('selected_company', `${localSelectCompany} | -100 | -100`);
    });
    gridApiRef.refreshCells({ force: true });
  };

  //IMPORT FUNCTIONS
  const fileInputRef = useRef<HTMLInputElement>(null);
  const importFilePicker = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const file = files[0];
    parseImportedCSV(file);
  };

  const parseImportedCSV = (file: File) => {
    Papa.parse(file, {
      header: true,
      complete: (result: any) => {
        completeImportParsing(result);
      },
      delimiter: ',',
      quoteChar: '"',
      skipEmptyLines: true,
    });
  };

  const refreshGrid = () => {
    if (gridApiRef) {
      gridApiRef.refreshCells();
    } else {
      toast.error('Did not call refresh');
    }
  };

  // const onClearClick = () => {
  //   setScoringGrid([]);
  //   refreshGrid();
  // };

  const completeImportParsing = (result: any) => {
    try {
      setScoringGrid([]);
      setScoringGrid(parseImportFields(result.data));
      refreshGrid();
    } catch (err) {
      toast.error('Did not parse file');
      console.error('Error fetching data:', err);
    }
  };

  const onDownloadTemplateClick = () => {
    downloadFile(createTemplate(), 'download_template.csv');
  };

  const onStartScore = () => {
    if (scoringGrid.length === 0) {
      toast.error('Empty grid -- can not score');
      return;
    }

    setSearching(true);
    setSearchError(false);

    // only send info needed for scoring
    const toScoreCompanies: IToScoreCompany[] = scoringGrid.map((scoreGridCompany: IScoringGridCompany) => ({
      id: scoreGridCompany.id,
      email: scoreGridCompany.email ?? '',
      fcode: scoreGridCompany.fcode ?? '',
      ticker_symbol: scoreGridCompany.ticker_symbol ?? '',
      user_company: scoreGridCompany.user_company ?? '',
      country: scoreGridCompany.country ?? '',
    }));

    apiCaller
      .startBulkScore(toScoreCompanies)
      .then((task_id: string) => {
        pollTaskStatus(task_id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateGridWithCompletedScore = (scoredCompaniesResponse: IScoredCompaniesResponse) => {
    const localScoringGrid: IScoringGridCompany[] = scoredCompaniesResponse.items?.map((scoredCompany: IScoredCompany) => {
      return {
        ...scoredCompany,
        selected_company: scoredCompany.alternative_companies.length > 0 ? displayCompany(scoredCompany.alternative_companies[0]) : '',
        upload_status: '',
      };
    });
    localScoringGrid.forEach((scoringGridCompany: IScoringGridCompany) => {
      scoringGridCompany.alternative_companies.push({ company_code: '', name: DELETE_ME, country: '', my_rank: -100, total: -100 });
    });
    setScoringGrid(localScoringGrid);
    refreshGrid();
    setSearching(false);
  };

  // return true if keep waiting, return false if done
  const onGetScore = async (task_id: string): Promise<boolean> => {
    if (task_id) {
      try {
        const scoredCompaniesResponse: IScoredCompaniesResponse = await apiCaller.getBulkScore(task_id);
        if (scoredCompaniesResponse.status === 'NOT_FOUND') {
          console.error('NOT_FOUND');
          setSearchError(true);
          setSearching(false);
        } else if (scoredCompaniesResponse.status === 'COMPLETED') {
          updateGridWithCompletedScore(scoredCompaniesResponse);
        } else if (scoredCompaniesResponse.status === 'IN_PROGRESS') {
          return true;
        }
      } catch (err) {
        console.error(err);
        setSearchError(true);
        setSearching(false);
      }
    } else {
      console.log('Task has not been started');
    }
    return false;
  };

  const pollTaskStatus = (task_id: string) => {
    const intervalId = setInterval(async () => {
      try {
        const continueToWait = await onGetScore(task_id);

        if (!continueToWait) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error fetching task status:', error);
        clearInterval(intervalId);
      }
    }, 5000); // Poll every 5 seconds
  };

  const displayUploadStatus = (gridEmail: string, gridCompanyCode: string, scoredUploadResponses: IScoredUploadResponse[]) => {
    // originally tested id coming back
    //   but if the user selects the same company more than once, this approach works:
    const item = scoredUploadResponses.find(
      (scoreUploadResponse) => scoreUploadResponse.email === gridEmail && scoreUploadResponse.company_code === gridCompanyCode
    );
    const already_in_portfolio = item?.already_in_portfolio ?? 0;
    const company_code_found = item?.company_code_found ?? 0;
    if (company_code_found === 0) return 'Not found';
    if (already_in_portfolio === 1) return 'Already exists';
    return 'Added';
  };

  const onUploadClick = () => {
    if (!isBulkLoaderEnabled) {
      toast.error('Feature is not supported');
      return;
    }
    if (scoringGrid.length === 0) {
      toast.error('Empty grid -- can not upload');
      return;
    }
    const proposed_email_company_code: IScoredUpload[] = scoringGrid
      .map((scoringGridCompany: IScoringGridCompany) => {
        const parsed_company_code = parseCompanyCode(scoringGridCompany.selected_company);
        return {
          id: scoringGridCompany.id,
          email: scoringGridCompany.email.toLowerCase().trim(),
          company_code: parsed_company_code.toLowerCase().trim(),
        };
      })
      .filter((scoredUpload: IScoredUpload) => {
        return scoredUpload.email.length > 0 && scoredUpload.company_code.length > 0;
      });

    const unique_email_company_codes: IScoredUpload[] = proposed_email_company_code.filter(
      (record, index, self) => index === self.findIndex((t) => t.email === record.email && t.company_code === record.company_code)
    );

    if (unique_email_company_codes.length === 0) {
      toast.error('No company codes selected or email is empty');
      return;
    }

    createSQLToAdd(unique_email_company_codes);

    setUploading(true);
    setUploadError(false);
    apiCaller
      .uploadUserCompany(unique_email_company_codes)
      .then((scoredUploadResponses: IScoredUploadResponse[]) => {
        setScoringGrid(
          scoringGrid.map((scoreGridItem: IScoringGridCompany) => {
            return {
              ...scoreGridItem,
              upload_status: displayUploadStatus(
                scoreGridItem.email,
                parseCompanyCode(scoreGridItem.selected_company),
                scoredUploadResponses
              ),
            };
          })
        );
        toast.success(`Uploaded`);
      })
      .catch((err) => {
        console.error(err);
        setUploadError(true);
        toast.error('Failed to upload');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const onExportClick = () => {
    if (scoringGrid.length === 0) {
      toast.error('Empty grid -- can not export');
      return;
    }
    const dateTime = new Date().toISOString().replace(/[-:]/g, '').replace(/\..+/, '');
    downloadFile(exportCSV(scoringGrid), `user_companies_${dateTime}.csv`);
  };

  return (
    <div>
      <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
        <AgGridReact
          rowData={scoringGrid}
          columnDefs={columnDefs}
          getRowId={(scoringGridRow: any) => {
            return scoringGridRow.data.id;
          }}
          singleClickEdit={true}
          rowSelection="single"
          onGridReady={(onGridReadyParams) => {
            setGridApiRef(onGridReadyParams.api);
          }}
        />
      </div>
      {/* <Button text="Clear" onClick={onClearClick}></Button> */}

      <div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 p-2">
            <Button text="Download template" onClick={onDownloadTemplateClick}></Button>
          </div>
          <div className="col-span-10 p-2">
            <div>Download a template to start with. Enter the user email and desired company name. Save the file as a CSV file type.</div>
          </div>
        </div>
        <div className="grid grid-cols-12 p-0">
          <div className="col-span-2 p-2">
            <Button text="Import" onClick={importFilePicker}></Button>
            {/* Import button clicks the input component.  The input component is hidden visually. */}
            <input style={{ display: 'none' }} type="file" accept=".csv" onChange={onImport} ref={fileInputRef} />
          </div>
          <div className="col-span-10 p-2">
            <div>Import your completed CSV file.</div>
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 p-2">
            <Button text="Score" onClick={onStartScore}></Button>
          </div>
          <div className="col-span-10 p-2">
            <div>Match the names you've entered against the FSS Company database. Select from the options you're presented with.</div>
            {searching && <h3>Scoring.. Please wait a few minutes..</h3>}
            {searchError && <h3>Error scoring</h3>}
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 p-2">
            <Button text="Upload" onClick={onUploadClick}></Button>
          </div>
          <div className="col-span-10 p-2">
            <div>Once you're happy with the matches, upload the data to the portfolio of the user in the email column.</div>
            {uploading && <h3>Uploading.. Please wait a few minutes..</h3>}
            {uploadError && <h3>Error uploading</h3>}
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-2 p-2">
            <Button text="Export" onClick={onExportClick}></Button>
          </div>
          <div className="col-span-10 p-2">
            <div>Optionally, you can download the final matched file for reference.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkLoaderGrid;
