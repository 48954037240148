import Highcharts, { Options } from 'highcharts';
import noDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useRef } from 'react';
import { TooltipOptionsExtended } from '../types/tooltip-data';
import '../styles/CompanyGraphs.scss';
import '../extensions/highcharts-plugins';
import { black, blue, GRAPH_FONT_SIZE } from '../constants/company-chart';
import { ICompanySummary } from '../types/company';
import {
  createComparablePercentCompanies,
  getPercentChartMinXMaxX,
  createComparableGraphDataSeries,
} from '../utils/compare-graph-functions';
import { breakPoints, scoreBucketName } from '../constants/downgrade-probability';
import { getFeatureFlags } from '../utils/config';
import useChartTitle from '../hooks/use-chart-title';

noDataModule(Highcharts);

export default function CompareGraph({
  filteredCompanies,
  allCompanies,
  list,
}: Readonly<{
  filteredCompanies: ICompanySummary[];
  allCompanies: ICompanySummary[];
  list?: string;
}>) {
  const ref = useRef<any>();
  const chartRef = useRef(null);
  const chartTitle = useChartTitle();

  const tooltipOptions: TooltipOptionsExtended = {
    useHTML: true,
    borderWidth: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    shadow: false,
    // charts crash without this function
    remainOnMouseout: function () {
      return false;
    },
  };

  const defaultPlotLineLabelOptions = {
    y: 20,
    style: { color: '#949494' }, // ow-grey
  };

  const comparablePercentCompanies = createComparablePercentCompanies(filteredCompanies);
  const [chartMinX, chartMaxX] = getPercentChartMinXMaxX(createComparablePercentCompanies(filteredCompanies));

  const options: Options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      animation: true,
      backgroundColor: 'transparent',
      height: 650,
      spacing: [10, 0, 10, 0],
      type: 'scatter',
      zooming: {
        type: 'xy',
      },
      resetZoomButton: {
        theme: {
          style: {
            display: 'none',
          },
        },
      },
      events: {
        load: function () {
          this.renderer
            .text('Improving', this.plotLeft, this.plotHeight + 65)
            .css({
              fontSize: '12px',
            })
            .add();

          this.renderer
            .text('Worsening', this.plotWidth, this.plotHeight + 65)
            .css({
              fontSize: '12px',
            })
            .add();
        },
      },
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: '<div style="color:#FF0000">No companies in your portfolio with valid scores match these filter criteria.</div>',
    },
    legend: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    noData: {
      useHTML: true,
    },

    plotOptions: {
      scatter: {
        cursor: 'pointer',
        marker: { enabled: true, radius: 5, symbol: 'circle' },
        lineWidth: 0,
        dataLabels: {
          align: 'right',
          enabled: true,
          padding: 10,
          formatter: function () {
            return this.point.name;
          },
          verticalAlign: 'middle',
        },
        point: {
          events: {
            click: function (p: any) {
              const company_code = p.point.options.company_code;
              // eslint-disable-next-line no-restricted-globals
              location.pathname = `company/${company_code}`;
            },
          },
        },
        tooltip: {
          headerFormat: '',
          pointFormat: `
      <div style="background: #fff;border: 1px solid #dadada;min-width: 140px; border-radius: 10px;z-index: 20;">
        <div style="color:#FFF;background: black;padding: 6px 10px 5px 10px;text-align: center;border-top-left-radius: 10px;border-top-right-radius: 10px;font-weight:bold;">
          {point.name}
        </div>
        <div style="display:flex;justify-content:center;align-items:center;padding:10px;flex-direction:column">
          <div style="display:flex;width:100%;align-items:center;margin-bottom:10px;">
            <div style="width: 50%;text-align: right;padding-right:4px;">Weekly %</div>
            <div style="width: 50%;font-size: 20px;font-weight: 700;line-height: 18px;padding-left:4px;">{point.x: .1f}%</div>
          </div>
          <div style="display:flex;width:100%;align-items:center;">
            <div style="width: 50%;text-align: right;padding-right:4px;">Score</div>
            <div style="width: 50%;font-size: 20px;font-weight: 700;line-height: 18px;color:#009de0;padding-left:4px;">{point.y: .0f}</div>
          </div>
        </div>
      </div>
      `,
        },
      },
    },
    series: createComparableGraphDataSeries(comparablePercentCompanies),
    title: { text: '' },
    tooltip: tooltipOptions,
    xAxis: [
      {
        max: chartMaxX,
        min: chartMinX,
        title: { text: 'Weekly % FSS score change', margin: 15, style: { color: black, fontSize: GRAPH_FONT_SIZE } },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'FSS Score',
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        labels: {
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        min: 0,
        max: 100,
        tickInterval: 20,
        lineColor: blue,
        lineWidth: 4,
        plotLines: [
          { color: '#DADADA', value: breakPoints[1], label: { text: scoreBucketName[1], ...defaultPlotLineLabelOptions } },
          { color: '#DADADA', value: breakPoints[2], label: { text: scoreBucketName[2], ...defaultPlotLineLabelOptions } },
          { color: '#DADADA', value: breakPoints[3], label: { text: scoreBucketName[3], ...defaultPlotLineLabelOptions } },
          { color: '#DADADA', value: breakPoints[4], label: { text: scoreBucketName[4], ...defaultPlotLineLabelOptions } },
          { color: 'transparent', value: 100, label: { text: scoreBucketName[5], ...defaultPlotLineLabelOptions } },
        ], // 'ow-light-grey': ,
        gridLineWidth: 0,
      },
    ],
    exporting: {
      fallbackToExportServer: false,
      allowHTML: true,
      enabled: true,
      chartOptions: {
        chart: {
          height: 650,
          backgroundColor: 'white',
        },
        tooltip: { enabled: false },
        title: {
          useHTML: true,
          text: chartTitle.formatChartTitle(!!list),
          align: 'center',
        },
      },
      scale: 2,
      sourceWidth: 1360,
      sourceHeight: 650,
      filename: `chart-graph-${list || 'Portfolio'}`,
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  const isDownloadChartEnabled = getFeatureFlags().has('download_chart');

  const exportChartAsPNG = () => {
    const chart = (chartRef.current as any)?.chart;
    chart.exportChartLocal();
  };

  const resetZoom = () => {
    const chart = (chartRef.current as any)?.chart;
    chart.zoomOut();
  };

  return (
    <div ref={ref}>
      <div className="flex justify-end items-center mb-4">
        {isDownloadChartEnabled && (
          <button onClick={exportChartAsPNG} className="fss-chart-button" title="Download chart">
            <img src="/icons/download.svg" alt="Download chart" />
            <div>Download chart</div>
          </button>
        )}
        <div className="fss-button-spacer"></div>
        <button onClick={resetZoom} className="fss-chart-button" title="Reset zoom level">
          <img src="/icons/reset.svg" alt="Reset zoom level" />
          <div>Zoom level</div>
        </button>
      </div>

      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  );
}
