import { atom } from 'recoil';
import ICompany, { ICompanyScoreHistory } from '../types/company';

export const companyAtom = atom<ICompany | null>({
  key: 'companyAtom',
  default: null,
});

export const companyScoreHistoryAtom = atom<ICompanyScoreHistory[]>({
  key: 'companyScoreHistoryAtom',
  default: [],
});

export const companyHistoryIdsAtom = atom<string>({
  key: 'companyHistoryIdsAtom',
  default: '',
});

export const companyHistoryLoadingAtom = atom<boolean>({
  key: 'companyHistoryLoadingAtom',
  default: true,
});
